import OpenAI from 'openai'
import { OpenAiFunction } from '../dtos/userPhone'

interface IGetMessageRequest {
  currentThreadId?: string
  apiKey: string
  assistantId: string
  userMessage: string
}

interface IGetMessageResponse {
  answer: string
  threadId: string
}

const getMessage = async ({
  currentThreadId,
  apiKey,
  assistantId,
  userMessage,
}: IGetMessageRequest): Promise<IGetMessageResponse> => {
  if (!userMessage) {
    return Promise.reject(new Error('Mensagem vazia'))
  }

  const openai = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  })

  const myAssistant = await openai.beta.assistants.retrieve(assistantId)
  const thread = await openai.beta.threads.create()
  const threadId = currentThreadId || thread.id

  await openai.beta.threads.messages.create(threadId, {
    role: 'user',
    content: userMessage,
  })

  const run = await openai.beta.threads.runs.create(threadId, {
    assistant_id: myAssistant.id,
    instructions: myAssistant.instructions,
  })

  let runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id)

  let retry = 0

  while (runStatus.status === 'in_progress' || runStatus.status === 'queued') {
    await new Promise((resolve) => setTimeout(resolve, 3000))
    console.log('Retrying...' + retry)
    retry += 1
    runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id)
  }

  switch (runStatus.status) {
    case 'requires_action':
    case 'completed': {
      const messages = await openai.beta.threads.messages.list(threadId)
      const responseAI = messages.data[0]
      .content[0] as OpenAI.Beta.Threads.Messages.TextContentBlock
      const answer = responseAI?.text?.value
      return { answer, threadId }
    }

    default:
      throw new Error(runStatus.last_error?.message || 'Erro inesperado, contate o suporte')
      
  }
}

export const getFunctions = async (apiKey: string, assistantId: string): Promise<OpenAiFunction[]> => {
  const openai = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  })

  const myAssistant = await openai.beta.assistants.retrieve(assistantId)

  const tools = myAssistant.tools

  const functions = tools.filter((f) => f.type === 'function')
  
  return functions.map((f: any) => ({ name: f.function.name, url: '' }))
}

export default getMessage
